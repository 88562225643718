import React, { useState } from "react"
import { css } from "@emotion/react"

const group = css`
    height: 100%;

    @media (min-width: 574px) {
        height: calc(100vh - 58px);
    }
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f2f2f2;
    flex-wrap: wrap;

    &  p, li {
      color: #4d4c9b;
      width: 100%;
      max-width: 700px;
      font-size: 19px;
    }
`

const groupContainer = css`
    background-color: white;
    padding: 2rem;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    max-width: 550px;
`

const title = css`
    position: relative;

    font-size: 3rem;
    font-weight: 500;
    font-family: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #4d4c9b;
    margin-bottom: 3rem;

    &::after {
    display: block;
    content: "";
    position: absolute;
    bottom: -10px;
    height: 2px;
    width: 80%;
    background-color: #e64853;
    animation: pulse .2s linear 1 alternate;
    }
`

const link = css`
    text-decoration: underline;
`

const logo = css`
  max-width: 100%;
  img {
    max-width: 100%;
  }
`

const Profiles = () => {
    return (
        <div css={group.styles}>
            <div css={groupContainer.styles}>
                <h2 css={title.styles}>Profils recherchés</h2>

                <p>Nous recherchons régulièrement de nouveaux collaborateurs pour les postes autour de la gestion de projet:</p>

                <ul>
                    <li>Coordinateur Projet (H/F)</li>
                    <li>Chef de Projet Infrastructure (H/F)</li>
                    <li>Chef de Projet Fonctionnel (H/F)</li>
                    <li>PMO (H/F)</li>
                </ul>
                <p><a css={link.styles} href="#contact">N’hésitez-pas à postuler directement</a></p>
            </div>

            <div css={logo.styles}>
                <img alt="Jaad" src="dotted-logo.png"/>
            </div>
        </div>
)}
export default Profiles
