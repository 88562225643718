import React, { useState } from "react"
import { Link } from "gatsby"
import { useForm, Controller } from "react-hook-form";
import { css } from "@emotion/react"

import Loading from "./svg/loading"

const thankYouMessage = css`
    text-align: center;
    display: block;
    width: 100%;
`

const section = css`
  @media (max-width: 575.98px) {
  padding-left: 10px;
  padding-right: 10px;
  height: auto;
  }
`
const blockContent = css`
  justify-content: start;

  @media(min-width: 600px) {
    justify-content: center;
  }
`

const title = css`
    position: relative;

    font-size: 2.5rem;
    font-weight: 500;
    font-family: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #4d4c9b;
    margin-bottom: 3rem;

    &::after {
      display: block;
      content: "";
      position: absolute;
      bottom: -10px;
      height: 2px;
      width: 80%;
      background-color: #e64853;
      animation: pulse .2s linear 1 alternate;
    }
`

const submitForm = async (data) => {
    const formData = new FormData();

    Object.entries(data).map(([name, value]) => {
        let val = value

        if (typeof(value) === 'object') {
            val = value[0]
        }

        formData.append(name, val);
    });

    return fetch('/.netlify/functions/contact-form', {
        method: 'POST',
        body: formData
    })
}

const Contact = ({ titre, contenu, image, lien}) => {
    const { register, handleSubmit, watch, control, resetField, formState: { isSubmitSuccessful, isSubmitting, errors } } = useForm();
    const filelist = watch('cv')
    const filename = filelist && filelist[0]?.name

    return (
        <section id={"contact-section"} css={section.styles} className="home-hr-block container">
            <div className="col-12 col-md-10">
                <div css={blockContent.styles} className="home-hr-block__content">
                    <h2 css={title.styles} className="home-hr-block__title">Nous contacter</h2>

                    {isSubmitSuccessful && <div css={thankYouMessage.styles}>
                        Merci pour votre intérêt, votre message a bien été pris en compte. Nous vous contacterons dès que possible.<br></br>Groupe JAAD</div>}
                    {!isSubmitSuccessful && <form onSubmit={handleSubmit(submitForm)}>
                        <input {...register("nom", { required: true })} className={`form-input`} placeholder="Nom" />
                        <input {...register("email", { required: true })} type="email" className={`form-input`} placeholder="Email" />
                        <input {...register("linkedin")} type="url" className={`form-input`} placeholder="https://www.linkedin.com/in/votre-compte/" />
                        <textarea {...register("message", { required: true })} className={`form-input`} placeholder="Votre message"/>

                        <div className={`fileUpload form-input ${filename && "uploaded"}`}>
                            {!filename && <label htmlFor={'cv'}>Attacher un CV</label>}
                            {filename && <div><p>{filename}</p><p><a href="#" onClick={() => resetField('cv')}>Annuler</a></p></div>}
                            <input
                                {...register("cv", { validate: (f) => f[0] && f[0].size < 3145728 })}
                                id='cv'
                                type="file"
                                className={'upload'}
                                accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                        </div>

                        {isSubmitting && <div className="form-loader"><Loading /></div>}
                        {!isSubmitting && <input className="form-input" type="submit" value="Envoyer" />}
                    </form>}
                </div>
            </div>
        </section>
    )
}

export default Contact

