import React, { useState } from "react"
import { css } from "@emotion/react"

const group = css`
    background-position: left;
    background-repeat: no-repeat;
    background-image: url("formateur.png");
    background-color: #F9F9F9;
    background-size: auto 100%;

    height: 100%;

    @media (min-width: 574px) {
        height: calc(100vh - 58px);
    }
    max-width: 1480px;
    margin: 0 auto;
    margin-right: auto;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: #4d4c9b;
      width: 100%;
      max-width: 700px;
      font-size: 19px;
    }
`

const groupContainer = css`
    background-color: #F9F9F9;
    display: flex;
    flex-wrap: wrap;
    max-width: 650px;
    padding: 2.5rem;
    position: relative;

    width: 100%;

    @media (min-width: 1394px) {
        margin-left: auto;
    }
`

const logos = css`
    display: flex;
    justify-content: end;
    width: 100%;
      height: 100%;

    @media (max-width: 574px) {
      margin-top: 4rem;
    }

    img {
      width: 266px;
      margin-left: auto;
    }
`

const title = css`
    position: relative;

    font-size: 2.5rem;
    font-weight: 500;
    font-family: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #4d4c9b;
    margin-bottom: 3rem;

    &::after {
      display: block;
      content: "";
      position: absolute;
      bottom: -10px;
      height: 2px;
      width: 80%;
      background-color: #e64853;
      animation: pulse .2s linear 1 alternate;
    }
`

const toto = css`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
        justify-content: end;
    }

    @media (min-width: 1394px) {
        justify-content: center;
        margin-left: 616px;
    }
`

const Group = () => {
    return (
        <div css={group.styles}>
            <div css={toto.styles}>
                <div css={groupContainer.styles}>
                    <h2 css={title.styles}>Le Groupe</h2>

                    <p>Notre principale activité est notre cabinet d’expertise en gestion de projet avec pour objectif de devenir la référence en France.​</p>

                    <p>Cette envie est née de nos expériences personnelles dans de grandes ESN où nous avons manqué de suivi personnalisé, de formation et de plan de carrière afin d’atteindre nos objectifs personnels.​</p>

                    <p>Nous souhaitons apporter notre expérience pour accompagner des collaborateurs grâce à du mentorat, un plan de carrière personnalisé avec de la formation continue et des certifications pour atteindre l’excellence.​</p>

                    <div css={logos.styles}>
                        <img alt="" src="./pm.jpg"/>
                    </div>

                </div>
            </div>
        </div>
)}
export default Group
