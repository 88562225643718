import * as React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"

import Layout from "../components/layout"
import Nav from "../components/nav"
import SEO from "../components/seo"
import ReactFullpage from '@fullpage/react-fullpage';
import Header from "../components/header"

import Contact from "../components/contact"
import Footer from "../components/footer"
import Group from "../components/group"
import Expertise from "../components/expertise"
import Recrutement from "../components/Recrutement"
import Profiles from "../components/profiles"

const scrollDown = css`
    position: absolute;
    bottom: 1rem;
    left: 50%;
    margin-left: -.5rem;
    color: #e64853;
    text-align: center;
    @-webkit-keyframes jump {
    to {
    -webkit-transform:translateY(8px);
    transform:translateY(8px)
    }
    }

    @keyframes jump {
    to {
    -webkit-transform:translateY(8px);
    transform:translateY(8px)
    }
    }

    & > svg {
    transform: scale(1.3);
    width: 1rem;
    height: .625rem;
    animation: jump .5s linear infinite alternate;
    }
`

const doScrollDown = () => {
}

const IndexPage = () => {
    return (
        <Layout>
            <SEO title={"Page d'accueil"} />
            <Nav />
            <main>
                <ReactFullpage
                    licenseKey = {'K62LK-G4IIJ-IK017-JI44H-SNJQO'}
                    scrollingSpeed = {1000}
                    navigation={true}
                    responsiveWidth={"574"}
                    bigSectionsDestination={'top'}
                    paddingTop={"58px"}
                    anchors={["home", "group", "expertise", "recrutement", "profiles", "contact", "footer"]}

                    render={({ state, fullpageApi }) => {
                        return (
                            <ReactFullpage.Wrapper>
                                <div className={"section"} >
                                    <Header titre={"Help you grow..."} sousTitre={""} />
                                    <div css={scrollDown.styles} onClick={doScrollDown}>
                                        <svg viewBox="0 0 16 10">
                                            <polyline points="0,0 8,8 16,0" stroke-linecap="round" stroke="currentColor" stroke-width="2" fill="none"></polyline>
                                        </svg>
                                    </div>
                                </div>

                                <div className={"section"} >
                                    <Group />
                                </div>

                                <div className={"section"} style={{ background: "white" }}>
                                    <Expertise />
                                </div>

                                <div className={"section"} >
                                    <Recrutement />
                                </div>

                                <div className={"section"} >
                                    <Profiles />
                                </div>

                                <div className="section">
                                    <Contact />
                                </div>

                                <div class="section fp-auto-height" >
                                    <Footer />
                                </div>
                            </ReactFullpage.Wrapper>
                        )
                    }}
                />
            </main>
        </Layout>
    )
}

export default IndexPage
