import React, { useState } from "react"
import { css } from "@emotion/react"

const group = css`
    background-position: left;
    background-repeat: no-repeat;
    background-image: url("hr-2.png");
    background-color: #F9F9F9;
    background-size: auto 100%;

    height: 100%;

    @media (min-width: 574px) {
        height: calc(100vh - 58px);
    }
    max-width: 1480px;
    margin: 0 auto;

    margin-right: 'auto';
    margin-left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    &  p:first-child {
    padding-top: 2rem;
    }

    &  p:last-of-kind {
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    }
    &  p {
    color: #4d4c9b;
    width: 100%;
    max-width: 700px;
    font-size: 19px;
    }
`

const groupContainer = css`
    background-color: #F9F9F9;
    width: 650px;
    padding: 3rem;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    margin-left: auto;
    max-width: 650px;
`

const title = css`
    position: relative;

    font-size: 3rem;
    font-weight: 500;
    font-family: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #4d4c9b;
    margin-bottom: 3rem;

    &::after {
    display: block;
    content: "";
    position: absolute;
    bottom: -10px;
    height: 2px;
    width: 80%;
    background-color: #e64853;
    animation: pulse .2s linear 1 alternate;
    }
`

const image = css`
    width: 100%;
    margin-bottom: 1rem;
`

const Recrutement = () => {
    return (
        <div css={group.styles}>
            <div css={groupContainer.styles}>

                <h2 css={title.styles}>Politique RH</h2>

                <p>Notre ADN est basé sur 4 piliers se basant sur l’évolution de carrière du collaborateur mais toujours avec le même niveau d’excellence attendu par nos clients qui constitue notre image de marque.​</p>

                <img src="recrutement.png" css={image.styles}/>

                <p>Notre recrutement repose donc sur l’adéquation entre la personnalité, les compétences, l’état d’esprit et l’envie du collaborateur.​</p>
            </div>
        </div>
)}
export default Recrutement
